import React from 'react'
import './page-interpreters.scss'

export default function PageInterpreters() {


    const rules = ["Tulkilla on salassapitovelvollisuus.",
        "Tulkki ei käytä väärin mitään tulkkauksen yhteydessä tietoonsa tullutta.",
        "Tulkki ei ota vastaan toimeksiantoa, johon hän on esteellinen.",
        "Tulkki ei ota vastaan toimeksiantoa, johon hänellä ei ole riittävää pätevyyttä.",
        "Tulkki valmistautuu tehtäväänsä huolellisesti ja ajoissa.",
        "Tulkki tulkkaa kattavasti, ei jätä mitään pois eikä lisää mitään asiaankuulumatonta.",
        "Tulkki on puolueeton viestinvälittäjä eikä anna tunteidensa, asenteidensa ja mielipiteidensä vaikuttaa työhönsä.",
        "Tulkki ei toimi tulkattavien avustajana tai asiamiehenä eikä toimeksiantonsa aikana ole velvollinen hoitamaan muita kuin tulkkaustehtäviä.",
        "Tulkki käyttäytyy tilanteen ja toimeksiannon vaatimalla tavalla. ",
        "Tulkki ilmoittaa seikoista, jotka vaikeuttavat oleellisesti tulkkausta tulkkaustilanteessa.",
        "Tulkki ei toimi ammattikuntaansa haittaavalla tavalla.",
        "Tulkki kehittää jatkuvasti ammattitaitoaan."]

    const ruleLoop = rules.map((el, i) =>
        <li>{rules[i]}</li>
    );
    const Rules = () => {
        return (
            <div className="rules container  background-gray">
                <h4 className="header header-rules">Tulkkien ammattisäännöt:</h4>
                <ol className="limited-width">{ruleLoop}</ol>
            </div>
        );
    }

    const Recruit = () => {
        return (
            <div className="container">
                <h4 className="header">Hae tulkiksi</h4>
                <div className="paragraph">
                    <p>Jos olet kiinnostunut tulkkaustyötä lähetä CVsi sähköpostiin:</p>
                    <p><a href="mailto: pohjoissavon.tulkkikeskus@gmail.com"> pohjoissavon.tulkkikeskus@gmail.com</a></p>
                </div>
            </div>
        );
    }


    return (
        <div className="page-interpreters">
            <h1 className="header-top">Tulkeille</h1>
            <Rules/>
            <Recruit className=""/>
        </div>
    )
}
