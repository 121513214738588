import React from 'react'
import './row-section.scss'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import GraphicImage from '../../../images/aaltografiikka.svg';

export default function RowSection() {
    const isMobile = useSelector(state => state.dimensions.isMobile)

    const title = ["Perheyritys", "Luotettavuus", "Ammattitaito"]
    const text = ["Pohjois-Savon Tulkkikeskus on perustettu vuonna 2017. Vahvuuksiamme ovat laaja kielivalikoima sekä ystävällinen asiakaspalvelu. Olemme tavoitettavissa 24/7 joka päivä, sekä tulkeille että asiakkaillemme. Vastausviiveet ovat pääsääntöisesti erittäin lyhyet.",
        "Pohjois-Savon Tulkkikeskus on osakeyhtiö. Yrityksemme on valittu palveluntuottajaksi useille alueille eri puolilla Suomea. Monet sopimuksista ovat jo edenneet optiokausille. Vastaamme tulkkauksen toteutumisesta asiakkaillemme vuorokauden kuluessa tilauksesta.",
        "Kaikki tulkkimme ovat kirjallisesti sitoutuneet noudattamaan vaitiolo- ja salassapitovelvollisuutta sekä toimivat tulkkien ammattisäännöstön periaatteiden mukaisesti. Tiimissämme on pitkään alalla toimineita, koulutettuja tulkkeja sekä nuoria kielitaitoisia tulkkausalalle soveltuvia henkilöitä."]
    const Box = ({ title, text }) => {
        return (
            <div className={classNames('card limited-width', { 'col': !isMobile })}>
                <h3>{title}</h3>
                <p className="row-text">{text}</p>
                <hr className="line"></hr>
            </div>
        )
    }

    return (
        <div className={isMobile ? 'row-section-mobile' : 'row-section'}>
            <div className="row limited-width">
                <Box title={title[0]} text={text[0]} />
                <Box title={title[1]} text={text[1]} />
                <Box title={title[2]} text={text[2]} />
            </div>
            <div className="row-section-image-div">
                <img className="row-section-image" src={GraphicImage} alt="" />
            </div>
        </div>
    )
}
