import React, {useLayoutEffect, useState} from 'react';
import {useDispatch} from 'react-redux'
import {setWidth} from './store/actions'
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";
import './App.scss';

// Navbar
import Navbar from './components/Navbar/navbar';

// Pages
import PageHome from './components/PageHome/page-home';
import PageContactInfo from './components/PageContactInfo/page-contact-info';
import PageLanguages from './components/PageLanguages/page-languages';
import PageInterpreters from './components/PageInterpreters/page-interpreters';
import PageRecruitment from './components/PageRecruitment/page-recruitment';

// Footer
import Footer from './components/Footer/footer';
import Modal from './components/Modal/modal';


function App() {
	const [isModalVisible, setModalVisible] = useState(false)
	const dispatch = useDispatch()

	useLayoutEffect(() => {
		const updateSize = () => {
		  dispatch(setWidth(window.innerWidth))
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	 }, [dispatch]);

	return (
		<Router>
			<div>
				<Navbar />
				<Modal isVisible={isModalVisible} onClose={() => setModalVisible(false)}/>

				{/* Routes */}
				<Switch>
					<Route exact path="/">
						<PageHome />
					</Route>
					<Route path="/yhteystiedot">
						<PageContactInfo />
					</Route>
					<Route path="/kielet">
						<PageLanguages/>
					</Route>
					<Route path="/tulkki">
						<PageInterpreters/>
					</Route>
					<Route path="/henkilöstöpalvelut">
						<PageRecruitment/>
					</Route>
				</Switch>

				<Footer onShowModal={() => setModalVisible(true)}/>
			</div>
		</Router>

	);
}

export default App;
