import React from 'react'
import './page-recruitment.scss'

export default function PageRecruitment() {


    const Work = () => {

        const list = [
            "Työnkuva",
            "Toimeksiannon alkamisaika ja kesto sekä työajat",
            "Paikka ja osoite, jossa työ toteutuu",
            "Työntekijältä vaadittavat pätevyydet ja työkokemus jos työn suorittaminen näitä edellyttää. Voit myös mainita, mikäli erityinen koulutus tai työkokemus ei toimeksiannossa ole välttämätöntä",
            "Mahdolliset erityistoiveet työntekijästä",
            "Yhteyshenkilö sekä ilmoittajan tiedot"
        ]

        const div1 = (
            <div className="container">
                <div className="">
                    <h4 className="header">Hae työtä</h4>
                    <div className="paragraph">
                        <p>Lähetä vapaamuotoinen hakemus ja CV:si sähköpostitse osoitteeseen <a href="mailto: henkilostopalvelut@gmail.com">henkilostopalvelut@gmail.com</a>, niin otamme sinuun yhteyttä.</p>
                    </div>

                </div>
            </div>
        )

        const div2 = (
            <div className="container">
                <div className="">
                    <h4 className="header">Hae työntekijää</h4>
                    <div className="paragraph">
                        <p>Jos sinulla on työ, johon tarvitset tekijän, lähetä ilmoituksesi sähköpostitse osoitteeseen <a href="mailto: henkilostopalvelut@gmail.com">henkilostopalvelut@gmail.com</a>. Voit hakea työntekijää myös puhelimitse numerosta <a href="tel:045 229 8588">045 229 8588</a>.</p>
                    </div>
                </div>
            </div>
        )

        const div3 = (
            <div className="inner">
                <div className="middle-container">
                    <h5>Työntekijää hakiessa ilmoita seuraavat tiedot</h5>
                    {list.map((el, i) => <p style={{marginBottom: '4px', marginTop: 0, fontSize: '1.1rem'}} key={i}>- {list[i]}</p>)}
                </div>
            </div>
        )

        return (
            <div className="">
                <div className="w100 background-gray">
                    <div className="flex limited-width">
                        {div1}
                        {div2}
                    </div>
                </div>
                {div3}
                <div style={{width: '400px', maxWidth: '80%', backgroundColor: '#333', height: '1px', margin: 'auto'}}/>
                <div className="container" style={{textAlign: 'center', marginBottom: '40px'}}>
                    <h5 style={{marginTop: '40px'}}><a href="mailto: henkilostopalvelut@gmail.com">henkilostopalvelut@gmail.com</a></h5>
                    <h5><a href="tel:045 229 8588">045 229 8588</a></h5>
                </div>
            </div>
        )
    };

    return (
        <div className="page-recruitment">
            <h1 className="header-top">Henkilöstöpalvelut</h1>
            <Work className="pb-1" />
        </div>
    );
}
