import React from 'react'
import './modal.scss'

export default function Modal({ isVisible, onClose }) {
   if (!isVisible) return null
   return (
      <div className="modal-bg">
         <div className="modal">
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
               <button onClick={onClose}>Sulje</button>
            </div>
            <Terms/>
         </div>
      </div>
   )
}


const Terms = () =>  {
	return (
		<div>
			<div className="container terms-container">

			<h1>Käyttöehdot</h1>


			<h3>1 Yleistä</h3>
			<p>
			Käyttäessäsi näitä Pohjois-Savon Tulkkikeskus Oy:n verkkosivuja, sitoudut noudattamaan seuraavia käyttöehtoja sekä hyväksyt evästeiden käytön. Käyttäjän tulee välittömästi poistua näiltä verkkosivuilta, mikäli käyttäjä ei hyväksy näitä ehtoja kokonaisuudessaan.
			<br/>
			Pohjois-Savon Tulkkikeskus Oy:lla on oikeus päivittää näitä käyttöehtoja, verkkosivuston sisältöä, estää pääsy sivuille tai lopettaa ylläpito ilman erillistä ilmoitusta. Annat sitoumuksesi näiden ehtojen soveltamiseen heti, kun asioit näillä sivuilla. Pohjois-Savon Tulkkikeskus Oy pidättää itsellään kaikki oikeudet näihin verkkosivuihin, mitä ei nimenomaisesti ole tässä myönnetty.
			</p>


			<h3>2 Oikeudet</h3>
			<p>
			Näiden verkkosivujen sisältöä on sallittua käyttää, kopioida tai tulostaa, tai tallentaa ainoastaan henkilökohtaisiin tarpeisiin. Näillä sivuilla sijaitsevan tietosisällön saattaminen yleisön saataviin esittämällä, levittämällä tai kopioimalla on kiellettyä ilman Pohjois-Savon Tulkkikeskus Oy:n etukäteistä kirjallista suostumusta.
			<br/>
			Näillä sivuilla sijaitsevan tiedon oikeellisuutta, luotettavuutta, täsmällisyyttä tai saatavuutta ei taata miltään osin.
			</p>


			<h3>3 Muut ehdot</h3>
			<p>
				Nämä verkkosivut voivat sisältää linkkiyhteyksiä kolmannen osapuolen verkkosivustoille, jotka on julkaistu tai tehty muun kuin Pohjois-Savon Tulkkikeskus Oy:n toimesta. Näillä sivustoilla vierailu voi tapahtua vain käyttäjän toimesta. Näihin kolmannen osapuolen tarjoamiin verkkosivustoihin sovelletaan kolmannen osapuolen käyttö- ja muita mahdollisia sivuston käyttöä määritteleviä ehtoja, eikä Pohjois-Savon Tulkkikeskus Oy vastaa näiden sivustojen sisällöstä ja aineistosta.
			</p>

			<h3>4 Erimielisyyksien ratkaiseminen</h3>
			<p>
				Näiden sivujen käytöstä johtuvat erimielisyydet pyritään ratkaisemaan osapuolten välisillä neuvotteluilla. Mikäli osapuolet eivät pääse asiasta sovintoon, riita ratkaistaan lopullisesti Pohjois-Savon käräjäoikeudessa.
			</p>

			<br/><br/><br/><br/>


			<h1>Tietoa evästeistä</h1>

			<h3>1 Evästeet</h3>
			<p>
				Näitä verkkosivuja käyttämällä hyväksyt käyttämämme evästeet (”cookies”).
			</p>

			<h3>2 Mikä on eväste?</h3>
			<p>
				Evästeet ovat pieniä tekstitiedostoja, jotka selain tallentaa käyttäjän päätelaitteelle. Evästeet sisältävät yksilöllisen anonyymin tunnisteen, josta saadut rajalliset tiedot liittyvät ainoastaan verkkosivuston käyttötapoihin.
			</p>

			<h3>3 Mitä tietoja voidaan kerätä?</h3>
				<ul>
				<li>käytetty päätelaite (tietokone, älypuhelin, tabletti)</li>
				<li>selaimen versio</li>
				<li>käyttöjärjestelmä</li>
					<li>näytön tarkkuus</li>
					<li>vierailun aika ja kesto</li>
					<li>maakohtainen sijaintitieto (tarkkaa sijaintitietoa ei kerätä),</li>
					<li>verkkosivujen käytöstä johdetut tiedot, kuten verkkosivujen käyttö- ja selaustiedot</li>
			</ul>

			<h3>4 Mihin näitä tietoja käytetään?</h3>
			<p>
				Näitä tietoja hyödynnämme verkkosivujen analysoinnissa, kehittämisessä ja käyttökokemuksen parantamisessa. Nämä evästeet eivät kerää missään tilanteessa henkilö- tai muita tietoja, joista käyttäjä voitaisiin tunnistaa. Istuntotunnisteena on sattumanvarainen numerosarja, josta käyttäjää ei voida identifioida henkilökohtaisesti tai sijaintiperusteisesti. Käytettävän päätelaitteen ip-osoite on anonymisoitu.
			</p>

			<h3>5 Evästeiden poistaminen käytöstä</h3>
			<p>
				Mikäli et halua, että vierailustasi näillä sivuilla kerätään tietoja, voit halutessasi torjua nämä evästeet estämällä näiden käytön verkkoselaimesi asetuksista. Evästeiden käyttö voidaan estää selaimesta riippumatta. Pelkkä evästeiden tyhjentäminen ei kuitenkaan lopeta tai estä tiedon keruuta. Voit edelleen jatkaa verkkosivujen käyttöä evästeiden estämisestä huolimatta.
			</p>

			</div>

		</div>

	)
}


