import React from 'react'
import './page-home.scss'

import Intro from '../PageHome/Intro/intro';
import RowSection from '../PageHome/RowSection/row-section';
import Products from '../PageHome/Products/products';
import Contact from '../PageHome/Contact/contact';
import ProductsMobile from '../PageHome/Products/products-mobile';

export default function PageHome() {
	return (
		<div className="page-home">
			<Intro/>
			<RowSection/>
			<Products/>
			<ProductsMobile/>
			<Contact/>
		</div>
	)
}