import React from 'react'
import './page-languages.scss'
import languages from '../../../src/language-codes.json'
import availableLanguages from '../../../src/languages.json'

export default function PageLanguages() {

    const languageObjects = []
    availableLanguages.forEach(langCode => {
        const lang = languages.find(l => l.code === langCode)
        if (lang) languageObjects.push(lang)
    });

    languageObjects.sort((a, b) => {
        if(a.fi < b.fi) { return -1; }
        if(a.fi > b.fi) { return 1; }
        return 0;
    })

    var rows = languageObjects.map((lang, index) => {
        return (
            <tr className="tr" key={index}>
                <td>{lang.fi}</td>
                {/* <td>{lang.en}</td> */}
            </tr>
        )
    });

    return (
        <div className="page-languages">
            <div className="header">
                <h1>Kielet</h1>
            </div>
            <div className="table-container">
                <table className="limited-width table">
                    <tbody>
                        <tr>
                        {/* <th>Kieli</th>
                        <th>Language</th> */}
                        </tr>
                        {rows}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
