import React from 'react'
import './contact.scss'
import Phone from '../../../images/phone.svg';
import EmailImage from '../../../images/email.svg';

export default function Contact() {
    return (
        <div className="contact text-middle-center">
            <hr className="hr-long"></hr>
            <div className="textDiv">
                <h1>OTA YHTEYTTÄ</h1>
                <p><img className="icon" src={Phone} alt="Phone"/><a href="tel:040 1847500">040 1847500</a></p>
                <p><img className="icon" src={EmailImage} alt="Email"/><a href="mailto:pohjoissavon.tulkkikeskus@gmail.com">pohjoissavon.tulkkikeskus@gmail.com</a></p>
            </div>
            <hr className="hr-long"></hr>
        </div>
    )
}