import React, { useState } from 'react';
import './navbar.scss';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'
import Arrow from '../../images/arrow.svg';

import { ReactComponent as Logo } from '../../images/tulkkikeskus_logo_white.svg';

export default function Navbar() {
    const isMobile = useSelector(state => state.dimensions.isMobile)
    if (isMobile) return <MobileNavbar />
    return (
        <nav className="navbar">
            <ul>
                <Links />
                <div className="header">
                    <Link to="/"><Logo className="logo" /></Link>
                </div>
            </ul>
        </nav>
    )
}

const Links = () => {
    return (
        <>
            <li> <Link to="/yhteystiedot">YHTEYSTIEDOT</Link></li>
            <li> <Link to="/henkilöstöpalvelut">HENKILÖSTÖPALVELUT</Link> </li>
            <li> <Link to="/tulkki">TULKEILLE</Link> </li>
            <li><a href="https://tilaus.tulkki247.fi" target="_blank" rel="noopener noreferrer">TILAUSLOMAKE</a></li>
            <li><Link to="/kielet">KIELET</Link></li>
            <li> <Link to="/">ETUSIVU</Link> </li>
        </>
    )
}


const MobileNavbar = () => {
    const [isCollapsed, collapse] = useState(true)
    return (
        <div className="navbar-mobile">
            <div className="header">
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Link to="/"><Logo className="logo" /></Link>
                    <button onClick={() => collapse(!isCollapsed)}>
                        <img className={isCollapsed ? "arrow-down" : "arrow-up"} src={Arrow} alt="toggle" />
                    </button>
                </div>

                {!isCollapsed &&
                    <div onClick={() => collapse(true)}>
                        <Links />
                    </div>
                }
            </div>
        </div>
    )
}
