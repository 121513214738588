import React from 'react'
import './page-contact-info.scss'
import { useSelector } from 'react-redux'

export default function PageContactInfo() {

	const isMobile = useSelector(state => state.dimensions.isMobile)

	const people = [{ "nimi": "Mohammad Shahidehnia", "nimike": "Toimitusjohtaja ja tulkkien vastuuhenkilö", "numero": "040 960 3289" },
	{ "nimi": "Kirsi Maria Shahidehnia", "nimike": "Talousvastaava", "numero": "" },
	{ "nimi": "Tarja Iivanainen", "nimike": "Tilausten koordinaattori", "numero": "" }]

	const personInfoLoop = people.map((el, i) =>
		<div className="person-info" key={i}>
			<h5>{people[i].nimi}</h5>
			<p>{people[i].nimike}</p>
			<p><a href={`tel:${people[i].numero}`}>{people[i].numero}</a></p>
		</div>
	);

	const People = () => {
		return (
			<div className="container contacts background-gray">
				<h4 className="header">Yhteyshenkilöt</h4>
				<div className="people">{personInfoLoop}</div>
				<Orders/>
			</div>
		);
	}

	const Orders = () => {
		return (
			<>
			<h5>Tilaukset</h5>
			<p><a href="tel:0401847500">040 184 7500</a></p>
			<p><a href="tel:0452298588">045 229 8588</a></p>
			</>
		);
	}


	return (
		<div className="page-contact-info">

			<header>
				<h1>Yhteystiedot</h1>
			</header>

			<div className="employer-info">

				<div className={isMobile ? 'page-contact-info' : 'page-contact-info'}>
					<People />
				</div>
			</div>

			{/* <iframe width="600" height="450" frameborder="0" src="https://www.google.com/maps/embed/v1/place?key=API_KEY&q=Space+Needle,Seattle+WA" allowfullscreen></iframe> */}
			<div
				className="limited-width"
			>
				<iframe title="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1836.6885104634994!2d27.603246116189005!3d62.59069928291636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4684b72a48fcabf5%3A0x29dd7fed492240b3!2sPohjois-Savon%20Tulkkikeskus!5e0!3m2!1sen!2sfi!4v1578033223870!5m2!1sen!2sfi" width="100%" height="450" frameBorder="0" style={{ border: 0 }} allowFullScreen=""></iframe>
			</div>

		</div>
	)
}
