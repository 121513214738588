import React from 'react'
import './footer.scss'
import { useSelector } from 'react-redux'
//import { Link } from "react-router-dom";

import { ReactComponent as Logo } from '../../images/tulkkikeskus_logo_white.svg';

export default function Footer({ onShowModal }) {

	const isMobile = useSelector(state => state.dimensions.isMobile)

	const ContactInfo = () => {
		const content = (
			<>
				<p>Viitostie 3009 A</p>
				<p>71470 Oravikoski</p>
				<p><a href="tel:040 1847500">040 184 7500</a></p>
				<p><a href="tel:045 229 8588">045 229 8588</a></p>
				<p><a href="mailto:pohjoissavon.tulkkikeskus@gmail.com">pohjoissavon.tulkkikeskus@gmail.com</a></p>
			</>
		)

		if (isMobile) {
			return (
				<div> {content} </div>
			)
		}

		return (
			<div style={{borderLeft: 'solid 1px #989898', textAlign: 'left', paddingLeft: '2rem'}}>
				{content}
			</div>
		)
	}

	// const Navigation = () => {
	// 	return (
	// 		<div className={classNames({ 'col divider': !isMobile, 'divider-mobile': isMobile })}>
	// 			<h4>Navigointi</h4>
	// 			<p><Link to="/">Etusivu</Link></p>
	// 			<p><Link to="/kielet">Kielet</Link></p>
	// 			<p><a href="https://tilaus.tulkki247.fi">Tilauslomake</a></p>
	// 			<p><Link to="/tulkki">Tulkeille</Link></p>
	// 			<p><Link to="/yhteystiedot">Yhteystiedot</Link></p>
	// 		</div>
	// 	)
	// }

	return (
		<footer>
			<div className="row" style={{display: isMobile ? 'block' : 'flex', alignItems: 'center', justifyContent: 'center'}}>
				<Logo className="logo" />
				<ContactInfo />
			</div>
			<div className="bottom" style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: '48px' }}>
				<p>©2020 POHJOISSAVON TULKKIKESKUS OY</p>
				<p className="d" style={{ marginLeft: '8px', color: '#989898' }}>||</p>
				<button style={{ color: '#989898' }} onClick={onShowModal}>käyttöehdot</button>
			</div>
		</footer>
	)
}
