import React from 'react'
import './intro.scss'
import  GraphicImage from '../../../images/aaltografiikka.svg';

export default function Intro() {
    return (
        <div className="intro">
            <h1>Pohjois-Savon Tulkkikeskus</h1>
            <p className="par">
					Tulkkaus- ja käännöspalvelut tarvitsemallanne kielellä<br></br>
               tarvitsemassanne paikassa.
				</p>
            <img className="image-intro" src={GraphicImage} alt=""/>
        </div>
    )
}
