import {SET_WIDTH} from '../types'


const initialState = {
	isMobile: false,
	width: null,
}

const DimensionsReducer = (state = initialState, action) => {
	switch (action.type) {
      case SET_WIDTH:
			const width = action.width
			const isMobile = width < 1024
			return {
				width,
				isMobile
			}
      default:
			return state
	}
}

export default DimensionsReducer
