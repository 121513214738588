import GlobeImage from '../../../images/etatulkkaus.svg';
import ApplicationImage from '../../../images/247-logo.svg';
import SpeakImage from '../../../images/lasnaolotulkkaus.svg';
import BookImage from '../../../images/kaannos.svg';

const titles = ["Etätulkkaus", "Läsnäolotulkkaus", "Käännös", "Tulkki 24/7 sovellus"]
const texts = [
    "Etätulkkaus soveltuu tilanteisiin, joissa tulkin paikalla oleminen ei ole välttämätöntä. Tulkkaus tapahtuu puhelimitse. Varausvahvistuksen yhteydessä tilaajalle välitetään tulkkauksen suorittavan henkilön nimi ja puhelinnumero. Tilaaja soittaa tulkille varattuun aikaan.",
    "Kun tulkkia tarvitaan paikan päällä, tilataan läsnäolotulkkaus. Läsnäolotulkkaus toimii paremmin tilanteissa, joissa tulkattavia henkilöitä on useita, tapaamisen aikana käsitellään monimutkaisia asioita tai kyseessä on esimerkiksi luento tai koulutustilaisuus.",
    "Käännös on kirjallinen, sanasta sanaan käännetty asiakirja, joka voi olla epävirallinen tai virallinen. Epävirallinen käännös on hinnaltaan edullisempi, koska työn suorittaa muu kuin auktorisoitu kääntäjä. Viralliset käännökset suorittaa aina auktorisoitu kääntäjä. Virallisia käännöksiä ovat esimerkiksi erilaiset lupa-asiakirjat tai viranomaisten todistukset. Epävirallinen käännös soveltuu muihin teksteihin, esimerkiksi terveydenhuollon epikriiseihin tai erilaisiin päätöksiin.",
    "Tulkki 24/7 on uudenlainen palvelu pikaisiin tulkkaustarpeisiin. Sovellus yhdistää tulkkauksen osapuolet helposti ja nopeasti ilman ajanvarausta, minuuttihinnalla. Tällä hetkellä sovellus on ladattavissa Android-laitteisiin. Sovellus on erittäin helppokäyttöinen, ja kielivalikoima on laaja."]
const images = [GlobeImage, SpeakImage, BookImage, ApplicationImage]

export {titles, texts, images}
