import React from 'react'
import './products.scss'
import { titles, texts, images } from './variables';

export default function Products() {

    return (
        <div className='products'>

            <div className="container bg-grey">
                <div className="product-card row">
                    <div className="col image">
                        <img src={images[0]} alt=""></img>
                    </div>
                    <div className="col text">
                        <h3>{titles[0]}</h3>
                        <p>{texts[0]}</p>
                    </div>
                </div>
            </div>

            <div></div>
            <div className="container">
                <div className="product-card row">
                    <div className="col text">
                        <h3>{titles[1]}</h3>
                        <p>{texts[1]}</p>
                    </div>
                    <div className="col image">
                        <img src={images[1]} alt=""></img>
                    </div>
                </div>
            </div>

            <div></div>

            <div className="container bg-grey">
                <div className="product-card row">
                    <div className="col image">
                        <img src={images[2]} alt=""></img>
                    </div>
                    <div className="col text">
                        <h3>{titles[2]}</h3>
                        <p>{texts[2]}</p>
                    </div>
                </div>
            </div>

            <div></div>

            <div className="container">
                <div className="product-card row">
                    <div className="col text">
                        <h3>{titles[3]}</h3>
                        <p>{texts[3]}</p>
                    </div>
                    <div className="col image">
                        <img src={images[3]} alt=""></img>
                    </div>
                </div>
            </div>

            <div></div>
        </div>
    )
}
