import React from 'react'
import './products.scss'
import { titles, texts, images } from './variables';

export default function ProductsMobile() {

    return (
        <div className='products-mobile'>

            <div className="product-card row bg-grey">
                <div className="image">
                    <img src={images[0]} alt=""></img>
                </div>
                <div className="text">
                    <h3>{titles[0]}</h3>
                    <p>{texts[0]}</p>
                </div>
            </div>

            <div></div>

            <div className="product-card row">
                <div className="image">
                    <img src={images[1]} alt=""></img>
                </div>
                <div className="text">
                    <h3>{titles[1]}</h3>
                    <p>{texts[1]}</p>
                </div>
            </div>

            <div></div>

            <div className="product-card row bg-grey">
                <div className="image">
                    <img src={images[2]} alt=""></img>
                </div>
                <div className="text hyphens">
                    <h3>{titles[2]}</h3>
                    <p>{texts[2]}</p>
                </div>
            </div>

            <div></div>

            <div className="product-card row limited-width">
                <div className="image">
                    <img src={images[3]} alt=""></img>
                </div>
                <div className="text">
                    <h3>{titles[3]}</h3>
                    <p>{texts[3]}</p>
                </div>
            </div>

            <div></div>
        </div>
    )
}
